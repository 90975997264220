import "./OurServices.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler, faHelmetSafety, faBuilding, faScrewdriverWrench, faBolt, faCircleUser, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const OurServices = () => {
  return (
    <section className="our-services bg-gray">
      <div className="container">
        <div className="section-title">
          <h3>
            OUR <span>SERVICES</span>
          </h3>
          <p>Sagatom engineering Ltd. wants to serve their honorable customers. The company has an efficient engineering team for installation &amp; after sales services. The company is providing complete project with all scope on turn key basis.</p>
        </div>
        <div className="row">
          <div className="services-flex">
            {/* 1 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faCircleUser} />
                </div>
                <div className="details">
                  <h3>Project consultancy</h3>
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Architectural</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Structural</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Mechanical</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Electrical</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Plumbing</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Project Management Consultancy</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faPenRuler} />
                </div>
                <div className="details">
                  <h3>architectural solution</h3>
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Conceptual Design</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Schematic Design</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Design Development</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Exterior Design</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Interior Design</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faHelmetSafety} />
                </div>
                <div className="details">
                  <h3>civil construction</h3>
                  {/* <p>Mega projects, Road, Bridge, Culvert, Industry, Commercial and Residential Building</p> */}
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Mega projects (Road, Bridge, Culvert)</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Industry</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Commercial Building</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Residential Building</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faBuilding} />
                </div>
                <div className="details">
                  <h3>steel building</h3>
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Foundation Design and Construction</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Steel Fabrication</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Steel Erection</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Roofing and Cladding Installation</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 5 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                </div>
                <div className="details">
                  <h3>mechanical solution</h3>
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Fire Detection and Protection System</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Mechanical Fabrication</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Piping Fabrication (water, air, gas line)</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Mechanical Ventilation</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 6 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faBolt} />
                </div>
                <div className="details">
                  <h3>Electrical solution</h3>
                  <ul class="services-list">
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Power Distribution System Design</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Lighting System Design</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>LV & MV Instruments</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Lightning Protection Systems (LPS) </span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Electrical distribution Panels</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      <span>Automation System</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
