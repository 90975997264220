import "./WriteUs.css";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WriteUs = ({ title, span }) => {
  const form = useRef();
  const [disable, setDisable] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setDisable(true);
    emailjs
      .sendForm("service_s3h3arq", "template_s5bk5wh", form.current, {
        publicKey: "xqrwpLOdY-fBabuej",
      })
      .then(
        () => {
          toast.success("Email Sent!");
        },
        (error) => {
          toast.error("Email Send Failed!");
          console.log("Email FAILED...", error.text);
        }
      );
    setDisable(false);
  };

  return (
    <section className="contactWrap">
      <div className="container">
        <div className="section-title">
          <h3>
            {title} <span>{span}</span>
          </h3>
        </div>
        <form ref={form} onSubmit={sendEmail} id="xs-contact-form" className="xs-form">
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" name="name" placeholder="Your name" id="xs_contact_name" required />
            </div>
            <div className="col-md-6">
              <input type="email" className="form-control invaild" name="email" placeholder="Your email" id="xs_contact_email" required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" name="phn_num" placeholder="Your phone number" id="xs_contact_phone" required />
            </div>
            <div className="col-md-6">
              <input type="text" className="form-control" name="subject" placeholder="Subject" id="xs_contact_subject" required />
            </div>
          </div>
          <textarea name="message" placeholder="Message" id="x_contact_massage" className="form-control message-box" cols={30} rows={10} defaultValue={""} />
          <div className="readmore text-center">
            <button className="main-btn btn-1 btn-1e" disabled={disable}>
              SEND MESSAGE
            </button>
          </div>
          <ToastContainer />
        </form>
      </div>
    </section>
  );
};

export default WriteUs;
