import "./AboutCompany.css";

const AboutInfo = () => {
  return (
    <section className="about-us">
      <div className="about-img-pro" style={{ background: "url(/images/about-us-construction.webp) center/cover" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-push-6">
            <div className="section-title">
              <h3>ABOUT OUR COMPANY</h3>
              <p>SAGATOM ENGINEERING LIMITED, a dynamic and young company, stands out from the total project with architectural &amp; structural design with civil construction, electrical &amp; mechanical work market by its effcient and ability.</p>
              <p>The company has been growing rapidly and continuously increasing its sales through quality of work with the product, consistent quality and strict adherence to delivery schedules.</p>
              <p>This company has achieved customer's reliance and gratification for it's best quality of work, continuous after services and the e cient consultancy of it's highly trained up and experienced engineers and technicians.</p>
              <p>Sagatom engineering Ltd. wants to serve their honorable customers. The company has an efficient engineering team for installation & after sales services. The company is providing individual and complete project with all scope on turn key basis.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfo;
