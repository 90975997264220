import Footer from "../components/Footer/Footer";
import InnerHeading from "../components/InnerHeading/InnerHeading";
import Navbar from "../components/Navbar/Navbar";
import ValuableClients from "../components/ValuableClients/ValuableClients";
import WriteUs from "../components/WriteUs/WriteUs";
import Map from "../components/Map/Map";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import { useEffect } from "react";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Navbar />
      <InnerHeading heading="Contact Us" />
      <GetInTouch />
      <WriteUs title="DROP" span="YOUR MESSAGE" />
      <Map />
      <ValuableClients />
      <Footer />
    </>
  );
};

export default ContactPage;
