import "./WhyChooseUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const WhyChooseUs = () => {
  return (
    <section className="whychoose-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="section-title">
              <h3>
                Our special support
                <br />
                <span>Why Choose Us </span>
              </h3>
              <p>Sagatom engineering Ltd. wants to serve their honorable customers. The company has an efficient engineering team for installation &amp; after sales services. The company is providing complete project with all scope on turn key basis.</p>
            </div>
            <div className="whychoose-description">
              <ul className="row">
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>Best Solution for Desired Work</span>
                </li>
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>Quality Assurance</span>
                </li>
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>Highest Quality Control</span>
                </li>
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>Satisfaction Guarantee</span>
                </li>
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>Customer Satisfaction</span>
                </li>
                <li className="col-md-6 col-sm-6 col-xs-6">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="green" />
                  <span>24 Hour Best Service</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="about-video-item">
              <div className="about-video-img">
                <img src="/images/welcome-1.webp" alt="" className="img-responsive" />
                {/* <a href="https://www.youtube.com/watch?v=Vn_FGpZJqUs" className="video-play mfp-iframe xs-video">
                  <FontAwesomeIcon icon={faPlay} />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
