import "./PortfolioCarousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { useEffect } from "react";

const PortfolioCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#my-gallery",
      children: "a",
      counter: false,
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <section className="portfolio-area">
      <div className="container-fluid">
        <div className="section-title">
          <h3>
            OUR RECENT <span>PROJECTS</span>
          </h3>
          <p>Featuring our works ongoing projects portfolio and snapshots</p>
        </div>

        <div className="portfolio-inner" id="my-gallery">
          <div className="row">
            <Slider {...settings}>
              {/* 1 */}
              <a href="/images/services-carousel/services-1.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-1.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 2 */}
              <a href="/images/services-carousel/services-2.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-2.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 3 */}
              <a href="/images/services-carousel/services-3.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-3.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 4 */}
              <a href="/images/services-carousel/services-4.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-4.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 5 */}
              <a href="/images/services-carousel/services-5.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-5.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 6 */}
              <a href="/images/services-carousel/services-6.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-6.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 7 */}
              <a href="/images/services-carousel/services-7.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-7.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 8 */}
              <a href="/images/services-carousel/services-8.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-8.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Architectarul Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 9 */}
              <a href="/images/services-carousel/services-9.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-9.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Civil Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 10 */}
              <a href="/images/services-carousel/services-10.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-10.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Civil Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 11 */}
              <a href="/images/services-carousel/services-11.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-11.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Civil Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 12 */}
              <a href="/images/services-carousel/services-12.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-12.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Civil Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 13 */}
              <a href="/images/services-carousel/services-13.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-13.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Civil Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 14 */}
              <a href="/images/services-carousel/services-14.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-14.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Steel Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 15 */}
              <a href="/images/services-carousel/services-15.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-15.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Steel Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>
              {/* 16 */}
              <a href="/images/services-carousel/services-16.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-16.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Steel Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 17 */}
              <a href="/images/services-carousel/services-17.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-17.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 18 */}
              <a href="/images/services-carousel/services-18.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-18.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 19 */}
              <a href="/images/services-carousel/services-19.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-19.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 20 */}
              <a href="/images/services-carousel/services-20.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-20.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 21 */}
              <a href="/images/services-carousel/services-21.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-21.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 22 */}
              <a href="/images/services-carousel/services-22.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-22.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 23 */}
              <a href="/images/services-carousel/services-23.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-23.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 24 */}
              <a href="/images/services-carousel/services-24.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-24.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>

              {/* 25 */}
              <a href="/images/services-carousel/services-25.webp" target="_blank" data-pswp-width="600" data-pswp-height="400">
                <div className="image-hover-effect-2">
                  <img src="/images/services-carousel/services-25.webp" className="img-responsive" alt="" />
                  <div className="caption">
                    <h3>Mechanical Work</h3>
                    <p>Click to zoom</p>
                  </div>
                </div>
              </a>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioCarousel;
