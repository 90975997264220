import { Link } from "react-router-dom";
import "./GetQuote.css";

const GetQuote = () => {
  return (
    <section className="get_qoute" style={{ background: "url(/images/get_qoute.webp) 0 0/cover no-repeat fixed" }}>
      <div className="container">
        <div className="section-title">
          <h2>Your ultimate solution is at your fingertips</h2>
          <h4>Sagatom Engineering Ltd. wants to serve their honorable customers. The company has an efficient engineering team for installation & after sales services. The company is providing complete project with all scope on turn key basis.</h4>
          <div className="readmore">
            <Link to="/contact" className="main-btn btn-1 btn-1e white_color">
              Submit
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetQuote;
