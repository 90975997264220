import { Link, useLocation } from "react-router-dom";
import "./InnerHeading.css";

const InnerHeading = ({ heading }) => {
  let location = useLocation();
  let pathName = location.pathname.substring(1); // Remove the leading slash

  return (
    <section className="inner-heading" style={{ backgroundImage: `url(/images/${pathName}-banner.webp)`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="overlay"></div>
      <div className="container">
        <h1>{heading}</h1>
        <ul className="xs-breadcumb">
          <li>
            <Link to="/"> Home</Link> / {pathName}
          </li>
        </ul>
      </div>
    </section>
  );
};

export default InnerHeading;
