import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook, faSquareTwitter, faSquareGooglePlus, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="footer-info">
              <div className="footer-logo">
                <Link to="/">
                  <img className="footer-logo-default" src="/images/logo.svg" alt="" />
                </Link>
              </div>
              <p>SAGATOM ENGINEERING LIMITED, Link dynamic and young company, stands out from the total project with architectural &amp; structural design with civil construction, electrical &amp; mechanical work market by its effcient and ability.</p>
              <ul className="footer-social">
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faSquareFacebook} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faSquareTwitter} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faSquareGooglePlus} />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-info">
                  <h3 className="footer-title">Usefull Links</h3>
                  <ul className="service-link">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="footer-info">
                  <h3 className="footer-title">Our Services</h3>
                  <ul className="service-link">
                    <li>
                      <Link to="/">Project consultancy</Link>
                    </li>
                    <li>
                      <Link to="/">architectural solution</Link>
                    </li>
                    <li>
                      <Link to="/">civil construction</Link>
                    </li>
                    <li>
                      <Link to="/">steel building</Link>
                    </li>
                    <li>
                      <Link to="/">mechanical solution</Link>
                    </li>
                    <li>
                      <Link to="/">Electrical solution</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-5 col-sm-6">
                <div className="footer-info">
                  <h3 className="footer-title">Contact Us</h3>
                  <ul className="footer-adress">
                    <li>
                      <FontAwesomeIcon icon={faLocationDot} />
                      <span>House 1, Road 3, Section 6, Mirpur, Dhaka 1216</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faPhone} />
                      <span class="title">Call Us :</span>
                      <span class="phone-content">
                        <Link to={"tel:+88 01740-214622"} className="white_color">
                          +88 01740-214622,
                        </Link>
                        <br />
                        <Link to={"tel:+88 01912-137844"} className="white_color">
                          +88 01912-137844
                        </Link>
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <span class="title">Email :</span>
                      <span>info@sagatom.com, sagatom2u@gmail.com</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-content">
          <p>
            <Link target="_blank" to="/">
              © {new Date().getFullYear()} Sagatam Engineering Limited.
            </Link>
          </p>
          <p>
            <Link to="https://www.linkedin.com/in/hasibhosen/" target="_blank">
              Developed by Hasib
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
