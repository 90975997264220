import "./ValuableClients.css";
import Slider from "react-slick";

const ValuableClients = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section className="brand-section bg-gray">
      {/*container start*/}
      <div className="container">
        <div className="section-title">
          <h3>
            OUR honorable <span>clients</span>
          </h3>
        </div>
        <div className="brand-content">
          <div className="brand-slider">
            <Slider {...settings}>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-1.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-2.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-3.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-4.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-5.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-6.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-7.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-8.png" alt="" className="img-responsive" />
              </div>
              <div className="logo-grey-style">
                <img src="/images/valueable-clients/logo-9.png" alt="" className="img-responsive" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuableClients;
