import "./Counter.css";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faListCheck, faUsers, faAward } from "@fortawesome/free-solid-svg-icons";

const Counter = () => {
  return (
    <section className="counter">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12 counter-item">
            <div className="counterbox">
              <div className="counter-icon">
                <FontAwesomeIcon icon={faAward} />
              </div>
              <div className="counter_area">
                <CountUp end={6} className="counter-number" enableScrollSpy={true} />
                <span className="counter-text">Years Of Experience</span>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-xs-12 counter-item">
            <div className="counterbox">
              <div className="counter-icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <div className="counter_area">
                <CountUp end={20} className="counter-number" enableScrollSpy={true} />
                <span className="counter-text">Expert</span>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-xs-12 counter-item">
            <div className="counterbox">
              <div className="counter-icon">
                <FontAwesomeIcon icon={faListCheck} />
              </div>
              <div className="counter_area">
                <CountUp end={40} suffix=" +" className="counter-number" enableScrollSpy={true} />
                <span className="counter-text">Projects</span>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-xs-12 counter-item">
            <div className="counterbox">
              <div className="counter-icon">
                <FontAwesomeIcon icon={faStar} />
              </div>
              <div className="counter_area">
                <CountUp end={40} suffix=" +" className="counter-number" enableScrollSpy={true} />
                <span className="counter-text">Happy Clients</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter;
