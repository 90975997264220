import { Link } from "react-router-dom";
import "./AboutInfo.css";

const AboutInfo = () => {
  return (
    <section className="about-info">
      <div className="about-img-pro" style={{ background: "url(/images/about-us-construction.webp) center/cover" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-push-6">
            <div className="section-title">
              <h3>
                About Us
                <br />
                <span>Who we are</span>
              </h3>
              <p>SAGATOM ENGINEERING LIMITED, a dynamic and young company, stands out from the total project with architectural &amp; structural design with civil construction, electrical &amp; mechanical work market by its effcient and ability.</p>
              <p>The company has been growing rapidly and continuously increasing its sales through quality of work with the product, consistent quality and strict adherence to delivery schedules.</p>
            </div>
            <div className="about-description">
              <div className="readmore">
                <Link to="/about" className="main-btn btn-1 btn-1e">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfo;
