import "./BestServices.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler, faHelmetSafety, faBuilding, faScrewdriverWrench, faBolt, faCircleUser } from "@fortawesome/free-solid-svg-icons";

const BestServices = () => {
  return (
    <section className="services bg-gray">
      <div className="container">
        <div className="section-title">
          <h3>
            OUR BEST <span>SERVICES</span>
          </h3>
          <p>Sagatom engineering Ltd. wants to serve their honorable customers. The company has an efficient engineering team for installation &amp; after sales services. The company is providing complete project with all scope on turn key basis.</p>
        </div>
        {/*row start*/}
        <div className="row">
          <div className="serviceList">
            {/* 1 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faCircleUser} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">Project consultancy</Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faPenRuler} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">architectural solution</Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faHelmetSafety} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">civil construction</Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faBuilding} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">steel building</Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">mechanical solution</Link>
                  </h3>
                </div>
              </div>
            </div>
            {/* 6 */}
            <div className="single-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faBolt} />
                </div>
                <div className="details">
                  <h3>
                    <Link to="/">Electrical solution</Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*row end*/}
        <div className="readmore text-center">
          <Link to="services" className="main-btn btn-1 btn-1e">
            All Services
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BestServices;
