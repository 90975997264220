import Navbar from "../components/Navbar/Navbar";
import InnerHeading from "../components/InnerHeading/InnerHeading";
import Footer from "../components/Footer/Footer";
import OurServices from "../components/OurServices/OurServices";
import ValueableClients from "../components/ValuableClients/ValuableClients";
import WriteUs from "../components/WriteUs/WriteUs";
import { useEffect } from "react";

const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Navbar />
      <InnerHeading heading="Services" />
      <OurServices />
      <WriteUs title="WRITE" span="US" />
      <ValueableClients />
      <Footer />
    </>
  );
};

export default ServicesPage;
