import Navbar from "../components/Navbar/Navbar";
import WriteUs from "../components/WriteUs/WriteUs";
import ValueableClients from "../components/ValuableClients/ValuableClients";
import Footer from "../components/Footer/Footer";
import ProjectsIsotope from "../components/ProjectsIsotope/ProjectsIsotope";
import InnerHeading from "../components/InnerHeading/InnerHeading";
import { useEffect } from "react";

const ProjectsPage = () => {
useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}, []);

  return (
    <>
      <Navbar />
      <InnerHeading heading="Projects" />
      <ProjectsIsotope />
      <WriteUs title="WRITE" span="US" />
      <ValueableClients />
      <Footer />
    </>
  );
};

export default ProjectsPage;
